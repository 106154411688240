import React, { Component, useEffect, useState } from 'react';
import { Images } from '../../utils/Images';
import { validEmail } from '../../utils/utility';
import { useNavigate } from 'react-router-dom';
import { loadError, loadSuccess, updateloginStatus, APIHOST, setCandy, isLoggedIn, setToken, setQuest, hasDoneQuest } from '../../utils/gaia';
import Axios from 'axios';
const Login = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  
  useEffect(()=>{
    if(isLoggedIn()){navigate(`/home`)}
  },[])


  const handleSubmit = (evt) =>{
    evt.preventDefault();
    if(!validEmail(email) || password === ""){
      loadError("All fields are required")
      return;
    }
    setLoading(true)
    Axios.post(`${APIHOST}login`, {
      email: email,
      password: password,
    }).then(function(res){
        let {data} = res;
        updateloginStatus(true);
        setToken(data.stamp);
        setCandy(data.session);
        setLoading(false)
        loadSuccess(data.msg)
        setTimeout(()=>{  window.location.href =  "/home" },1500)
    }).catch(function(er){
      setLoading(false)
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error ?? er.response.data.message)
        }else{
          loadError("A network error occured.")
        }
      }else{
        loadError(er.message)
      }
      
    })
  }

  return (
    <>
    <main className="common-bg">
        <section className="bg-img">
           <div className="nav-home">
                <a href="/"><img src={Images.homebtn} alt="" />   </a>
           </div>
           <div className="common-center">
                <div className="container login-form-container">
                    <form onSubmit={handleSubmit} className="submit-form">
                        <div className="mb-4">
                            <label className="form-label">Staff-mail</label>
                            <input type="email" className="form-control" onChange={(e)=>setEmail(e.target.value)} /> 
                        </div>
                        <div className="">
                            <label  className="form-label">Password</label>
                            <input type="password" className="form-control" onChange={(e)=>setPassword(e.target.value)} />
                        </div>
                        <div className="text-center">
                            <button type='submit' className={`btn mt-3 trans ${!loading ? "" : "disabled"}`} disabled={!loading ? false : true}>{loading ? <i className='fa fa-spinner fa-spin'></i> : ""}<img src={Images.submitbtn} alt="" /></button>
                        </div>
                    </form>
                    
                </div>
            </div>
        </section>
    </main>
    </>
  );
}

export default Login;
