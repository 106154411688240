import React, { Component, useEffect, useState } from 'react';
import { Images } from '../../utils/Images';
import { validEmail } from '../../utils/utility';
import { useNavigate } from 'react-router-dom';
import { loadError, loadSuccess, updateloginStatus, APIHOST, setCandy, isLoggedIn, setToken, setQuest, hasDoneQuest } from '../../utils/gaia';
import Axios from 'axios';
const Home = (props) => {

  const navigate = useNavigate();

  useEffect(()=>{
    if(!isLoggedIn()){navigate(`/`)}
  },[])

 
  return (
    <>
        <main class="common-bg">
            <section class="bg-img">
                <div class="nav-home">
                    <a href="#"><img src={Images.homebtn} alt="" /></a>
                </div>
                <div class="common-center">
                    <div class="container">
                        <div class="directory-images text-center">
                            <a href="/generate"><img src={Images.btn2} alt="" class="mb-5" /></a>
                            <a href="/scan"> <img src={Images.btn1} alt="" /></a>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </>
  );
}

export default Home;
