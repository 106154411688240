import React, { useEffect,useState } from 'react';
import { Modal, ModalBody} from 'reactstrap';
import { Images } from '../../utils/Images';
import { QRCode } from 'react-qrcode-logo';

const MemberModal = (props) => {

    useEffect(()=>{
 
    },[props])


    return (
      < >
        <Modal className='modal-dialog-centered trans' isOpen={props.open} toggle={() => props.close()}>
          <ModalBody>
          <div class="common-center">
            <div class="container">
              <div class="member-data">
                <div class="member-box">
                  <div class="member-detail-box">
                    <div class="member-id-box">
                      <h4>MEMBERS ID</h4>
                      <p class="member-id">{props.member.mid}</p>
                    </div>
                    <div class="member-chances-box">
                      <h4>CHANCES</h4>
                      <p>{props.member.chances}</p>
                    </div>
                    <div class="member-point-box">
                      <h4>POINTS DETAILS</h4>
                      <p>{props.member.points}</p>
                    </div>
                  </div>
                </div>

                <div class="voucher-box">
                  <div class="vouchr-title">
                    <p>YOUR POINTS CAN BE CONVERTED TO</p>
                  </div>
                  <div class="voucher-btn-grp">
                    <div class="voucer-5x">
                      <p>{props.member.vouchers}x</p>
                    </div>
                    <div><a href=""><img src={Images.grp3} alt="" /></a></div>
                  </div>
                </div>
                <div class="convert-voucher-btn">
                  <a onClick={props.close}><img src={Images.donebtn} alt="" className='mb-2' /></a>
                  {props.member.vouchers > 0 &&<a onClick={props.redeem}><img src={Images.btnconvert} alt="" /></a>}
                </div>
              </div>
            </div>
          </div>
          </ModalBody>
        </Modal>
      </>
    );
}

export default MemberModal;