import React, { useEffect,useState } from 'react';
import { Modal, ModalBody} from 'reactstrap';
import { Images } from '../../utils/Images';
import { QRCode } from 'react-qrcode-logo';

const QrModal = (props) => {

    useEffect(()=>{
 
    },[props])


    return (
      < >
        <Modal className='modal-dialog-centered trans' isOpen={props.open} toggle={() => props.close()}>
          <ModalBody>
          <div className="container">
                <div className="generate-data">
                  <div className="main-scanner-box">
                    <div className="qr-scaner">
                    <QRCode value={props.qrCode} fgColor="#a11c3e" size="185" />
                    </div>
                   <button className='trans' onClick={() => props.close()}> <img src={Images.donebtn} alt="" /></button>
                  </div>
                    
                </div>
              </div>
          </ModalBody>
        </Modal>
      </>
    );
}

export default QrModal;