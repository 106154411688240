import React from 'react';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const PopSwal = withReactContent(Swal)

function Loading() {
  return <div>Loading...</div>;
}

export function showLoading(msg){
  PopSwal.fire({ 
    title: msg,
    allowEscapeKey: false,
    allowOutsideClick: false,
    showConfirmButton: false,
    didOpen: () => {
      PopSwal.showLoading();
    }
  });
}

export function loadSuccess(msg){
  PopSwal.fire({ 
    title: msg,
    icon: 'success',
    timer: 2000,
    showConfirmButton: false
  });
}

export function loadError(msg){
  PopSwal.fire({ 
    title: msg,
    icon: 'error',
    timer: 2000,
    showConfirmButton: true
  })
}

export function actionError(msg,action){
  PopSwal.fire({
    icon: 'danger',
    title: msg,
    showCancelButton: false,
    confirmButtonColor: "#e72520",
    confirmButtonText: "Ok",
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      action()
    }
  });  
}

export function updateloginStatus(status = false,expires=60){
  Cookies.set("islogged", status, {path: "/", expires : expires});
}

export function assignCountry(code,expires=60){
  Cookies.set("gyw_locale", code, {path: "/", expires : expires});
}

export function setToken(token,expires=60){
  Cookies.set("obulus", token, {path: "/", expires : expires});
}

export function setCandy(candy,expires=60){
  Cookies.set("candy", candy, {path: "/", expires : expires});
}

export function setIntro(intro,expires=60){
  Cookies.set("_i", intro, {path: "/", expires : expires});
}

export function setQuest(quest,expires=60){
  Cookies.set("_quaelag", quest, {path: "/", expires : expires});
}


export function hasDoneQuest(){
  return Cookies.get("_quaelag") ? true : false;
}


export function getToken(){
  return Cookies.get("obulus");
}

export function getCountry(){
  return Cookies.get("gyw_locale");
}

export function getCandy(){
  return Cookies.get("candy");
}

export function getIntro(){
  return Cookies.get("_i");
}

export function getArtefact(field = "theme"){
  try{
    var value = Cookies.get('artefacts')
    var value = JSON.parse(value);
    if(typeof value == "undefined"){
      return "";
    }else{
      return value[field];
    }
  }catch(e){
    return null;
  }
  
}


export function isLoggedIn(){ 
  return Cookies.get("islogged"); 
}

export function destroySession(){
  Cookies.remove("obulus",{ path: '/' });
  Cookies.remove("candy",{ path: '/' });
  Cookies.remove("islogged",{ path: '/' });
}

export function isFirstTime(){
  return getIntro() == null ? true : false;
}

let HOST = null;
let url = window.location.hostname;

if(url.includes("apacconnects.com")){
  HOST = "https://llapi.apacconnects.com";
}else{
  HOST = "http://127.0.0.1:8000";
  //HOST = "https://llapi.apacconnects.com";
}

export const APIHOST = `${HOST}/api/`
