
export function validEmail(email){
    const re = /@(?:jem\.com|.com)$/;
    return re.test(email);
}



export function parseTier(tier, spiel=false){
    const strings = {
        1 : "one", 2 : "two", 3 : "three", 4 : "four", 5 : "five", 6 : "six", 7 : "seven",
        8 : "eight", 9 : "nine", 10 : "ten", 11 : "eleven", 12 : "twelve", 13 : "thirteen", 14 : "fourteen", 15 : "fifteen"
    }
    return spiel ? `${strings[tier]} (${tier})` : `${strings[tier]}` 
}

export function openInNewTab(url){
    window.open(url, '_blank', 'noopener,noreferrer');
}