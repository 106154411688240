import React, { Component, useEffect, useState } from 'react';
import { Images } from '../../utils/Images';
import { validEmail } from '../../utils/utility';
import { useNavigate } from 'react-router-dom';
import { loadError, loadSuccess, updateloginStatus, APIHOST, setCandy, isLoggedIn, setToken, setQuest, hasDoneQuest, getCandy } from '../../utils/gaia';
import Axios from 'axios';
import QrModal from '../Components/QrModal';
const GenQr = (props) => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showQR, setShowQR] = useState(false);
  const [cardNo, setCardNo] = useState("");
  const [code, setCode] = useState("");


  const hideQR = () =>{
    setShowQR(false);
    setCardNo("")
    setCode("")
  }
  useEffect(()=>{
    if(!isLoggedIn()){navigate(`/`)}
  },[])

  const handleSubmit = (evt) =>{
    evt.preventDefault();
    if(cardNo === ""){
      loadError("Please enter a card no.")
      return;
    }
    setLoading(true)
    Axios.post(`${APIHOST}generate`, {
      cardNo: cardNo,
      session: getCandy(),
    }).then(function(res){
        let {data} = res;
        setLoading(false)
        setCode(data.code)
        setShowQR(true);
        loadSuccess(data.msg)
    }).catch(function(er){
      setLoading(false)
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error ?? er.response.data.message)
        }else{
          loadError("A network error occured.")
        }
      }else{
        loadError(er.message)
      }
      
    })
  }
 
  return (
    <>
        <main className="common-bg">
            <section className="bg-img">
                <div className="nav-home">
                    <a href="/home"><img src={Images.homebtn} alt="" />   </a>
                </div>
                <div className="common-center">
                    <div className="container qrcode-data">
                        <h2 >generate <br/> qr code</h2>
                        <h3>KEY IN CUSTOMER'S <br />CARD NUMBER</h3>
                        <p>this will generate unique qr code</p>
                        <form onSubmit={handleSubmit}>
                            <div><input type="text" value={cardNo} className="form-control" onChange={(e)=>setCardNo(e.target.value)}  /></div>
                            <div className="generate-btn">
                                {loading ? <div><i className='fa fa-spinner fa-2x text-light fa-spin'></i></div> :
                                <button type='submit' className='trans'><img src={Images.generatebtn} alt="" /></button>}
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </main>
        {showQR ? <QrModal open={showQR} qrCode={code} close={hideQR}  /> : ""}
    </>
  );
}

export default GenQr;
