import React, { Component, useEffect, useState } from 'react';
import { Images } from '../../utils/Images';
import { validEmail } from '../../utils/utility';
import { useNavigate } from 'react-router-dom';
import { loadError, loadSuccess, updateloginStatus, APIHOST, setCandy, isLoggedIn, setToken, setQuest, hasDoneQuest } from '../../utils/gaia';
import Axios from 'axios';
const Landing = (props) => {

  const navigate = useNavigate();

  useEffect(()=>{
    if(isLoggedIn()){navigate(`/home`)}
  },[])

 
  return (
    <>
        <main className="common-bg">
            <section className="loginpage bg-img">
                <div className="nav-home"></div>
                <div className="common-center">
                    <div className="container text-center">
                        <a href="/login" ><img src={Images.loginbtn} alt="" /></a>
                    </div>
                </div>
            </section>
        </main>
    </>
  );
}

export default Landing;
